(function () {
  'use strict';

  angular
    .module('wbOrder')
    .config(config);

  function config($stateProvider, authenticatedOnly) {
    $stateProvider
      .state('order', {
        url: '/order:auth/:type',
        templateUrl: 'wb-order/views/order.tpl.html',
        controller: 'OrderCtrl',
        controllerAs: 'order',
        resolve: {
          user: function (User, LoggedUser, _) {
            var loggedUser = LoggedUser.getUser();

            return (_.isNull(loggedUser)) ? new User() : loggedUser;
          },
          company: function (Company, LoggedUser, _) {
            var loggedUserCompany = LoggedUser.getCompany();

            return (_.isNull(loggedUserCompany)) ? new Company() : loggedUserCompany;
          },
          isAuthenticated: function (user, company, _) {
            return !_.isEmpty(user) && !_.isEmpty(company);
          },
          orderType: function ($state, $stateParams, $location, $translate, _) {
            if (parseInt($stateParams.type) > 0) {
              var type = $stateParams.type
                , typeStr = $translate.instant('order.type.' + type + '.url');

              $stateParams.type = typeStr;
              $state.params.type = typeStr;
              $location.path($location.path().replace('/' + type + '/', '/' + typeStr + '/'));
            }

            switch ($stateParams.type) {
              case $translate.instant('order.type.1.url'):
                return '1';
              case $translate.instant('order.type.2.url'):
                return '2';
            }
          },
          backendUnreachable: function (Company, isAuthenticated) {
            if (isAuthenticated) {
              return false;
            }

            return Company
              .query({ name: 'Edenred' })
              .$promise
              .then(function () {
                return false;
              })
              .catch(function () {
                return true;
              });
          }
        },
        data: {
          hasOrderToolbar: true
        }
      })
      .state('company-basic', {
        url: '/company-basic',
        templateUrl: 'wb-order/views/company-basic.tpl.html',
        controller: 'CompanyBasicCtrl',
        controllerAs: 'companyBasic',
        parent: 'order'
      })
      .state('typeSelector', {
        url: '/type-selector',
        templateUrl: 'wb-order/views/type-selector.tpl.html',
        controller: 'TypeSelectorCtrl',
        controllerAs: 'typeSelector',
        parent: 'order',
        resolve: {
          user: function (User, LoggedUser, _) {
            var loggedUser = LoggedUser.getUser();

            return (_.isNull(loggedUser)) ? new User() : loggedUser;
          },
          company: function (LoggedUser) {
            return LoggedUser.getCompany();
          },
          isAuthenticated: function (user, company, _) {
            return !_.isEmpty(user) && !_.isEmpty(company);
          },
        }
      })
      .state('fileUpload', {
        url: '/bulk-file-upload',
        templateUrl: 'wb-order/views/file-upload.tpl.html',
        controller: 'FileUploadCtrl',
        controllerAs: 'fileUpload',
        parent: 'order'
      })
      .state('multiWalletCardFileUpload', {
        url: '/multi-wallet-bulk-file-upload',
        templateUrl: 'wb-order/views/multi-wallet-card-file-upload.tpl.html',
        controller: 'MultiWalletCardFileUploadCtrl',
        controllerAs: 'multiWalletCardFileUpload',
        parent: 'order'
      })
      .state('contract', {
        url: '/contract',
        templateUrl: 'wb-order/views/contract.tpl.html',
        controller: 'ContractCtrl',
        controllerAs: 'contract',
        parent: 'order',
        resolve: {
          companyByVat: function (company, Company, _, isAuthenticated, $state, backendUnreachable, orderType) {
            var EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
              , selectedProductType = localStorage.getItem(EdenredECSselectedProductType)
              , isNewCardOrder = orderType === '1'
              , isMultiWalletSelected = selectedProductType === 'multiWallet';

            if (isAuthenticated) {
              isNewCardOrder && isMultiWalletSelected ? $state.go('multiWalletCardInvoice') : $state.go('invoice');
            }

            if (backendUnreachable) {
              return company;
            }

            if (_.isEmpty(company.owners) && !_.isEmpty(company.vatNumber)) {
              return Company
                .query({ vatNumber: company.vatNumber })
                .$promise
                .then(function (data) {
                  return _.defaultsDeep(new Company(), _.first(data));
                })
                .catch(function () {
                  company.owners = [];
                  return company;
                });
            }

            return company;
          }
        }
      })
      .state('invoice', {
        url: '/invoice',
        templateUrl: 'wb-order/views/invoice.tpl.html',
        controller: 'InvoiceCtrl',
        controllerAs: 'invoice',
        parent: 'order',
        resolve: {
          cartOrder: function (Order, Cart, _, orderType, $state, user, $translate) {
            return Cart
              .get({ cartId: orderType })
              .$promise
              .then(function (cart) {
                if (_.isNull(cart.order) || _.isEmpty(user)) {
                  $state.go('order', { type: (orderType) ? $translate.instant('order.type.' + orderType + '.url') : $translate.instant('order.type.1.url') });
                }
                return _.defaultsDeep(new Order(), cart.order);
              });
          }
        }
      })
      .state('multiWalletCardInvoice', {
        url: '/multi-wallet-invoice',
        templateUrl: 'wb-order/views/multi-wallet-card-invoice.tpl.html',
        controller: 'MultiWalletCardInvoiceCtrl',
        controllerAs: 'multiWalletCardInvoice',
        parent: 'order',
        resolve: {
          cardTypeId: function (CardType, cardTypeMap, _) {
            var EdenredECSselectedProductType = 'EdenredEcsSelectedProductType'
              , selectedProductType = localStorage.getItem(EdenredECSselectedProductType);

            return CardType.query().$promise
              .then(function (data) {
                return _.result(_.find(data, { 'category': cardTypeMap[selectedProductType] }), 'id');
              });
          },
          workingDays: function (Auxiliary) {
            return Auxiliary.getWorkingDays().$promise;
          }
        }
      })
      .state('success', {
        url: '/order:auth/:type/success',
        templateUrl: 'wb-order/views/success.tpl.html',
        controller: 'SuccessCtrl',
        controllerAs: 'success',
        restrict: authenticatedOnly
      })
      .state('itemList', {
        url: '/standard-item-list',
        templateUrl: 'wb-order/views/item-list.tpl.html',
        controller: 'ItemListCtrl',
        controllerAs: 'itemList',
        parent: 'order',
        resolve: {
          user: function (User, LoggedUser, _) {
            var loggedUser = LoggedUser.getUser();

            return (_.isNull(loggedUser)) ? new User() : loggedUser;
          }
        }
      })
      .state('comfortCardItemList', {
        url: '/comfort-card-item-list',
        templateUrl: 'wb-order/views/comfort-card-item-list.tpl.html',
        controller: 'ComfortCardItemListCtrl',
        controllerAs: 'comfortCardItemList',
        parent: 'order',
        resolve: {
          user: function (User, LoggedUser, _) {
            var loggedUser = LoggedUser.getUser();

            return (_.isNull(loggedUser)) ? new User() : loggedUser;
          }
        }
      })
      .state('multiWalletCardItemList', {
        url: '/multi-wallet-card-item-list',
        templateUrl: 'wb-order/views/multi-wallet-card-item-list.tpl.html',
        controller: 'MultiWalletCardItemListCtrl',
        controllerAs: 'multiWalletCardItemList',
        parent: 'order',
        resolve: {
          user: function (User, LoggedUser, _) {
            var loggedUser = LoggedUser.getUser();

            return (_.isNull(loggedUser)) ? new User() : loggedUser;
          }
        }
      })
      // .state('vipUpload', {
      //   url: '/file-upload',
      //   templateUrl: 'wb-order/views/vip-upload.tpl.html',
      //   controller: 'VipUploadCtrl',
      //   controllerAs: 'vipUpload',
      //   parent: 'order',
      //   data: {
      //     hasOrderToolbar: false
      //   },
      //   restrict: authenticatedOnly
      // })
      .state('orderStart', {
        url: '/order:auth/:type/start',
        templateUrl: 'wb-order/views/order-start.tpl.html',
        controller: 'OrderStartCtrl',
        controllerAs: 'orderStart'
      })
      .state('companyCallback', {
        url: '/company-callback',
        templateUrl: 'wb-order/views/company-callback.tpl.html',
        controller: 'CompanyCallbackCtrl',
        controllerAs: 'companyCallback',
        data: {
          skipNavigation: false,
          companyCallbackType: 'standard'
        }
      })
      .state('instantCompanyCallback', {
        url: '/instant-callback',
        templateUrl: 'wb-order/views/company-callback.tpl.html',
        controller: 'CompanyCallbackCtrl',
        controllerAs: 'companyCallback',
        data: {
          skipNavigation: true,
          isResponsiveState: true,
          companyCallbackType: 'standalone'
        }
      })
      .state('merchantCompanyCallback', {
        url: '/merchant-callback',
        templateUrl: 'wb-order/views/company-callback.tpl.html',
        controller: 'CompanyCallbackCtrl',
        controllerAs: 'companyCallback',
        data: {
          skipNavigation: true,
          isResponsiveState: true,
          companyCallbackType: 'merchant'
        }
      })
      .state('orderReplacementCard', {
        url: '/order-replacement-card',
        templateUrl: 'wb-order/views/order-replacement-card.tpl.html',
        controller: 'OrderReplacementCardCtrl',
        controllerAs: 'orderReplacementCard',
        resolve: {
          user: function (User, LoggedUser, _) {
            var loggedUser = LoggedUser.getUser();

            return (_.isNull(loggedUser)) ? new User() : loggedUser;
          },
          company: function (Company, LoggedUser, _) {
            var loggedUserCompany = LoggedUser.getCompany();

            return (_.isNull(loggedUserCompany)) ? new Company() : loggedUserCompany;
          }
        }
      })
      .state('multiWalletDataReconciliationList', {
        url: '/multi-wallet-data-reconciliations',
        templateUrl: 'wb-order/views/multi-wallet-data-reconciliation-list.tpl.html',
        controller: 'MultiWalletDataReconciliationListCtrl',
        controllerAs: 'multiWalletDataReconciliationList',
        resolve: {
          company: function (Company, LoggedUser, _) {
            var loggedUserCompany = LoggedUser.getCompany();

            return (_.isNull(loggedUserCompany)) ? new Company() : loggedUserCompany;
          }
        }
      });
  }
}());
